<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="row" id="selected_mare">
				<div class="col">
					<h3>2. {{ $t('pax.mes_juments') }}</h3>
					<div class="paragraphes">{{ $t('pax.text_mare_infos') }}</div>
				</div>
				<div class="col-auto mare_ajout">
					<router-link :to="{ name: 'horseAjout', params: {from: 'pax'} }" class="input-group-text"><span>{{ $t('pax.ajouter') }} <font-awesome-icon :icon="['fas', 'plus']" /></span></router-link>
				</div>
				<div class="col-12">
					<div class="row">
						<div :class="'col-12 col-md-'+col_mare+' mare px-2'" v-for="mare in mares" :key="mare.horse_id">
							<a href="#" class="ml-auto" @click.prevent="chooseMare(mare.horse_id)">
								{{ mare.horse_nom }}
								<font-awesome-icon class="ml-auto" :icon="['fal', 'angle-right']"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "SelectionMare",
		props: {
			force_refresh: {
				type: String,
				default: () => ( "" )
			},
			step_label: {
				type: String,
				default: () => null
			}
		},
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				addOrEditReady: false,
				mares: []
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false

				let mares = await this.getHorses('F')
				this.mares = mares

				this.addOrEditReady = true
			},

			async chooseMare(horse_id) {
				let mare = this.mares.find(elem => elem.horse_id == horse_id)
				this.$emit("update:mare", mare)
				this.$emit("update:step_label", "mare_choosed")
			},
		},
		computed:{
			col_mare() {
				if(this.mares.length >= 10) {
					return 6
				}
				return 12
			}
		},
		watch: {
			force_refresh() {
				this.init_component()
			},

			step_label(val) {
				if(val == 'stallions_selected') {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
